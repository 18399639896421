/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
.promo-slide .pcdinner {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .promo-slide .pcdinner .heading-btns {
    margin-top: 0px; }

.pcdlower:last-child {
  padding-bottom: 72px; }

.promobadgeclass {
  height: 30px; }

.pcdlower {
  margin-top: -7px; }

.product-badge.store {
  background: #1d3557;
  color: #FFF5E4; }

.product-badge.online {
  background: #1d3557;
  color: #FFF5E4; }

.product-badge.blue {
  background: #1B4D94;
  color: #FFF5E4; }

.product-badge.pool {
  background: #439CD6;
  color: #000000; }

.product-badge.wagon {
  background: #D4394D;
  color: #FFFFFF; }

.product-badge.banana {
  background: #F9D448;
  color: #1B4D94; }

.product-badge.caterpillar {
  background: #56A446;
  color: #000000; }

.product-badge.pumpkin {
  background: #ECAA50;
  color: #000000; }

.product-badge.ube {
  background: #9E6ED4;
  color: #000000; }
